// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCiBfBa_8PCRiioXhkIA720gOCysepDAjg",
  authDomain: "reverlyrealms.firebaseapp.com",
  projectId: "reverlyrealms",
  storageBucket: "reverlyrealms.appspot.com",
  messagingSenderId: "849837280412",
  appId: "1:849837280412:web:6993296845690695e7b159",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export { app };

