import { useEffect, useState } from "react";

const RoundOneInto = ({ game }) => {
  const [secondsToNext, setSecondsToNext] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsLeft = game?.nextRoundTime - Math.round(Date.now() / 1000);
      setSecondsToNext(secondsLeft);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="my-auto w-screen text-center">
        <h1 className="text-6xl">
          Round <span className="font-google-fredoka">1</span>
        </h1>
        <p className="font-google-sans">
          This round will begin in <strong>{secondsToNext}</strong> seconds.
          When the game begins type the logo you see. Points will be awarded for
          speed and correctness.
        </p>
      </div>
    </>
  );
};

export default RoundOneInto;
