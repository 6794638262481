import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../redux/auth";
import PlanCard from "../../Components/PlanCard/PlanCard";
import { loadStripe } from "@stripe/stripe-js";

function ManageAccount(props: any) {
  const [countryCode, setCountryCode] = useState("US");
  const auth: any = useSelector((state: any) => state.auth.value);

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => setCountryCode(res.country_code));
  }, []);
  const dispatch = useDispatch();
  const handlePlanSelect = async (plan: any) => {
    const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY ?? "";
    const grandRevelryUsd = process.env.REACT_APP_GRAND_REVELRY_USD ?? "";
    const grandRevelryCad = process.env.REACT_APP_GRAND_REVELRY_CAD ?? "";

    const revelryRoyaleUsd = process.env.REACT_APP_REVELRY_ROYALE_USD ?? "";
    const revelryRoyaleCad = process.env.REACT_APP_REVELRY_ROYALE_CAD ?? "";
    let productCode = "";
    if (plan === "Revelry Royale") {
      productCode = countryCode === "CA" ? revelryRoyaleCad : revelryRoyaleUsd;
    } else if (plan === "Grand Revelry") {
      productCode = countryCode === "CA" ? grandRevelryCad : grandRevelryUsd;
    }
    let newAuth = { ...auth, refresh: true };
    dispatch(setAuth(newAuth));
    let stripe = await loadStripe(publishableKey);
    if (stripe != null) {
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: productCode,
            quantity: 1,
          },
        ],
        mode: "subscription",
        successUrl: `https://revelryrealm.com/`,
        cancelUrl: `https://revelryrealm.com/account`,
        customerEmail: auth.email,
      });
      if (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 md:mx-auto">
          <div className="relative px-4 py-10 bg-white md:mx-8 shadow-lg rounded-lg sm:p-10">
            <div className="mx-auto">
              <div className="flex items-center space-x-5">
                <Link to="/" className="absolute left-0 top-0 mt-4 ml-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6"
                  >
                    <title />
                    <g id="Complete">
                      <g id="arrow-left">
                        <g>
                          <polyline
                            data-name="Right"
                            fill="none"
                            id="Right-2"
                            points="7.6 7 2.5 12 7.6 17"
                            stroke="#374151"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />

                          <line
                            fill="none"
                            stroke="#374151"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            x1="21.5"
                            x2="4.8"
                            y1="12"
                            y2="12"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#115173"
                  className="bi bi-person-circle scale-[2.5]"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
                <div className="block font-semibold text-xl self-start text-gray-700">
                  <h2 className="leading-relaxed">Account Settings</h2>
                </div>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="flex flex-col">
                    <label htmlFor="email" className="leading-loose">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      disabled
                      placeholder={auth.email}
                      className="px-4 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500 rounded-lg"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="newPlan" className="leading-loose">
                      Plans
                    </label>
                    <div className="block md:flex mt-2 space-y-4 md:space-y-0 md:space-x-4 justify-items-center grid md:flex">
                      <PlanCard
                        onClick={() => handlePlanSelect("Free")}
                        planName="Free"
                        bulletPoints={[
                          "Play with up to 3 players",
                          "Try some of our games",
                        ]}
                        isSelected={auth.plan === null}
                      />
                      <PlanCard
                        onClick={() => handlePlanSelect("Revelry Royale")}
                        planName="Revelry Royale"
                        bulletPoints={[
                          "Play with up to 8 players",
                          "Unlock exclusive games",
                        ]}
                        isSelected={auth?.plan?.planName === "Revelry Royale"}
                      />
                      <PlanCard
                        onClick={() => handlePlanSelect("Grand Revelry")}
                        planName="Grand Revelry"
                        bulletPoints={[
                          "Play with up to 40 players",
                          "Unlock additional games",
                        ]}
                        isSelected={auth?.plan?.planName === "Grand Revelry"}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-4 flex items-center space-x-4">
                  <button className="bg-secondary flex justify-center items-center w-full text-white px-4 py-3 rounded-lg focus:outline-none">
                    Update Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageAccount;
