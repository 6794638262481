import { useState, useEffect, useCallback } from "react";
import GameLobby from "../../Components/GameLobby/GameLobby";

import LogoWiz from "../../Components/Games/LogoWiz/LogoWiz";

import DrawQuest from "../../Components/Games/DrawQuest/DrawQuest";

import { useSelector, useDispatch } from "react-redux";
import { setGame } from "../../redux/game";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/user";
import { io } from "socket.io-client";

import { app } from "../../firebase";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const db = getFirestore(app);

// Get a list of cities from your database
async function getCities(db) {
  const citiesCol = collection(db, "games");
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map((doc) => doc.data());
  return cityList;
}

function GameLoader() {
  const newGameJoin = useSelector((state: any) => state.newGameJoin.value);
  const [socket, setSocket]: Array<any> = useState({});
  const game = useSelector((state: any) => state.game.value);
  const user = useSelector((state: any) => state.user.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCities(db).then((result)=>console.log(result));
    if (game?.lastConnected + 60 * 60 * 1000 < Date.now()) {
      dispatch(setUser(null));
      dispatch(setGame(null));
      navigate("/");
    } else {
      const socket = user
        ? io(process.env.REACT_APP_GAME_SERVER, {
            // Add logic to rejoin game
            auth: {
              rejoinUser: JSON.stringify({ ...user, gameId: game?.gameId }),
            },
          })
        : io(process.env.REACT_APP_GAME_SERVER, {
            // Add logic to rejoin game
            auth: {
              gameToken: newGameJoin?.gameToken,
              gameId: newGameJoin?.gameId,
              userName: newGameJoin?.userName,
            },
          });
      setSocket(socket);
      socket.on("connect", () => {
        console.log("Socket Connected");
        dispatch(setGame({ ...game, lastConnected: new Date().getTime() }));
      });

      socket.on("connect_error", (error) => {
        if (error.message === "unauthorized") {
          dispatch(setGame(null));
          dispatch(setUser(null));
          navigate("/");
        } else {
          console.log("Connecting Error");
        }
      });

      socket.on("disconnect", () => {
        console.log("Socket disconnected");

        dispatch(setGame({ ...game, lastConnected: new Date().getTime() }));
      });

      socket.on("game-id", (gameId: string) => {
        console.log(gameId);
      });

      socket.on("user", (userUpdate) => {
        dispatch(setUser(userUpdate));
      });

      socket.on("game-details", (gameDetailsUpdate) => {
        dispatch(setGame(gameDetailsUpdate));
      });
    }
  }, []);

  const next = useCallback(() => {
    socket?.emit("next-section", { game: game, user: user });
  }, [socket, user]);

  const submit = useCallback(
    (answer, question) => {
      socket?.emit("answer", {
        game: game,
        user: user,
        question: question,
        answer: answer,
      });
    },
    [socket, game, user]
  );

  const step = parseInt((game?.round ?? 0).toString());
  let gameComponent;
  switch (game?.game) {
    case "LogoWiz":
      gameComponent = <LogoWiz game={game} user={user} submit={submit} />;
    case "DrawQuest":
      gameComponent = <DrawQuest game={game} user={user} submit={submit} />;
  }

  return (
    <>
      {step === 0 && <GameLobby user={user} next={next} game={game} />}
      {step !== 0 && gameComponent}
    </>
  );
}

export default GameLoader;
