import { useEffect, useState } from "react";

const RoundTwoIntro = ({ game }) => {
  const [secondsToNext, setSecondsToNext] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsLeft = game?.nextRoundTime - Math.round(Date.now() / 1000);
      setSecondsToNext(secondsLeft);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="m-auto w-screen text-center max-w-3xl">
        <h1 className="text-6xl">
          Round <span className="font-google-fredoka">2</span>
        </h1>
        <p className="font-google-sans">
          This round will begin in <strong>{secondsToNext}</strong> seconds. In
          this round, 10 logos will be presented but each logo will be blurred.
          As the round progresses,the logos will become less blurred. Points will be awarded for
          speed and correctness.
        </p>
      </div>
    </>
  );
};

export default RoundTwoIntro;
