import Intro from "./Intro";
import RoundOneInto from "./RoundOneIntro";
import RoundOne from "./RoundOne";
import RoundTwo from "./RoundTwo";
import RoundTwoIntro from "./RoundTwoIntro";
import LeaderBoard from "../LeaderBoard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setGame } from "../../../redux/game";
import { useDispatch } from "react-redux";

const LogoWiz = ({ game, user, submit }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let timeout;
    if (game?.round === 25) {
      timeout = setTimeout(() => {
        dispatch(setGame(null));
        navigate("/");
      }, 60000);
    }
    return clearTimeout(timeout);
  }, [game?.round]);

  const gameComponents = [
    <Intro />,
    <RoundOneInto game={game} />,
    <RoundOne game={game} submit={submit} user={user} />,
    <RoundOne game={game} submit={submit} user={user} />,
    <RoundOne game={game} submit={submit} user={user} />,
    <RoundOne game={game} submit={submit} user={user} />,
    <RoundOne game={game} submit={submit} user={user} />,
    <RoundOne game={game} submit={submit} user={user} />,
    <LeaderBoard
      users={game?.users}
      text="Round one is done. Let's see how everyone did..."
    />,
    <LeaderBoard
      users={game?.users}
      text="Round one is done. Let's see how everyone did..."
    />,
    <RoundTwoIntro game={game} />,
    <RoundTwoIntro game={game} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <RoundTwo game={game} submit={submit} user={user} />,
    <LeaderBoard
      users={game?.users}
      text={"The game is done! Let's see how everyone did."}
    />,
    <LeaderBoard
      users={game?.users}
      text={"The game is done! Let's see how everyone did."}
    />,
  ];

  return (
    <>
      <div className="bg-slate-100 h-screen flex">
        {gameComponents[parseInt(game?.round) - 1]}
      </div>
    </>
  );
};

export default LogoWiz;
