function VerificationNeeded() {
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <div className="flex flex-col items-center justify-center bg-white">
            <div className="flex items-center justify-center bg-green-500 rounded-full w-24 h-24 mb-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                className="checkmark-icon w-12 h-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h1 className="text-2xl font-bold mb-4">
              Your Account Has Been Created
            </h1>
            <p className="text-lg text-gray-700">Please verify your email.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerificationNeeded;
