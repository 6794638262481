import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import User from "../User/User";


const Navbar = (props) => {

  const { displayAccount, signedIn } = props;
  const width = 500;


  let navigate = useNavigate();
  const authClick = (e) => {
    const path = `/sign${e.target.id === "sign-up" ? `up` : `in`}`;
    navigate(path);
  };

  let svgWidth = 397;
  let svgHeight = 71;

  if (width < 600) {
    svgWidth = 297;
    svgHeight = 53;
  }
  if (width < 400) {
    svgWidth = 198;
    svgHeight = 35;
  }

  return (
    <nav className="flex items-center justify-between flex-wrap border-b-2 border-slate-300 h-[15vh] py-6 px-3 md:px-4">
      <div className="flex items-start flex-shrink-0 text-black mr-6">
        <svg
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 397 71`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Revelry Realm">
            <path
              d="M14.3369 4.0293C18.4385 4.0293 21.8223 4.54199 24.4883 5.56738C27.1771 6.56999 29.1823 8.10807 30.5039 10.1816C31.8255 12.2552 32.4863 14.8984 32.4863 18.1113C32.4863 20.7546 32.0078 22.9648 31.0508 24.7422C30.0938 26.4967 28.8405 27.9209 27.291 29.0146C25.7415 30.1084 24.0895 30.9515 22.335 31.5439L36.041 54H29.2734L17.0371 33.1504H6.64648V54H0.835938V4.0293H14.3369ZM13.9951 9.05371H6.64648V28.2285H14.5762C18.6549 28.2285 21.6514 27.3968 23.5654 25.7334C25.5023 24.07 26.4707 21.6204 26.4707 18.3848C26.4707 14.9896 25.4453 12.5856 23.3945 11.1729C21.3665 9.76009 18.2334 9.05371 13.9951 9.05371Z"
              fill="black"
            />
            <path
              d="M62.9492 52.0957C62.9492 53.1474 62.0966 54 61.0449 54H52.6152C46.9079 54 42.2812 49.3733 42.2812 43.666V27.2129C42.2812 21.5056 46.9079 16.8789 52.6152 16.8789H61.0449C62.0966 16.8789 62.9492 17.7315 62.9492 18.7832V18.7832C62.9492 19.8349 62.0966 20.6875 61.0449 20.6875H52.6025C49.2861 20.6875 46.5977 23.376 46.5977 26.6924V26.6924C46.5977 30.0088 49.2861 32.6973 52.6025 32.6973H60.1309C61.1686 32.6973 62.0098 33.5385 62.0098 34.5762V34.5762C62.0098 35.6139 61.1686 36.4551 60.1309 36.4551H53.4658C49.6726 36.4551 46.5977 39.5301 46.5977 43.3232V43.3232C46.5977 47.1164 49.6726 50.1914 53.4658 50.1914H61.0449C62.0966 50.1914 62.9492 51.044 62.9492 52.0957V52.0957Z"
              fill="black"
            />
            <path
              d="M80.3164 54L66.0977 16.5391H72.1816L80.4189 39.2002C80.9886 40.7497 81.5697 42.4587 82.1621 44.3271C82.7546 46.1956 83.1647 47.6995 83.3926 48.8389H83.6318C83.9053 47.6995 84.361 46.1956 84.999 44.3271C85.637 42.4359 86.2181 40.7269 86.7422 39.2002L94.9795 16.5391H101.063L86.8105 54H80.3164Z"
              fill="black"
            />
            <path
              d="M126.844 52.0957C126.844 53.1474 125.991 54 124.939 54H116.51C110.802 54 106.176 49.3733 106.176 43.666V27.2129C106.176 21.5056 110.802 16.8789 116.51 16.8789H124.939C125.991 16.8789 126.844 17.7315 126.844 18.7832V18.7832C126.844 19.8349 125.991 20.6875 124.939 20.6875H116.497C113.181 20.6875 110.492 23.376 110.492 26.6924V26.6924C110.492 30.0088 113.181 32.6973 116.497 32.6973H124.025C125.063 32.6973 125.904 33.5385 125.904 34.5762V34.5762C125.904 35.6139 125.063 36.4551 124.025 36.4551H117.36C113.567 36.4551 110.492 39.5301 110.492 43.3232V43.3232C110.492 47.1164 113.567 50.1914 117.36 50.1914H124.939C125.991 50.1914 126.844 51.044 126.844 52.0957V52.0957Z"
              fill="black"
            />
            <path d="M141.682 54H135.974V0.816406H141.682V54Z" fill="black" />
            <path
              d="M170.7 15.8555C171.452 15.8555 172.238 15.901 173.059 15.9922C173.879 16.0605 174.608 16.1631 175.246 16.2998L174.528 21.5635C173.913 21.404 173.229 21.2786 172.478 21.1875C171.726 21.0964 171.019 21.0508 170.358 21.0508C168.854 21.0508 167.43 21.3584 166.086 21.9736C164.764 22.5661 163.602 23.432 162.6 24.5713C161.597 25.6878 160.811 27.0436 160.241 28.6387C159.672 30.2109 159.387 31.9655 159.387 33.9023V54H153.679V16.5391H158.396L159.011 23.4434H159.25C160.025 22.0534 160.959 20.7887 162.053 19.6494C163.146 18.4873 164.411 17.5645 165.847 16.8809C167.305 16.1973 168.923 15.8555 170.7 15.8555Z"
              fill="black"
            />
            <path
              d="M176.408 16.5391H182.492L190.764 38.2773C191.242 39.5534 191.687 40.7839 192.097 41.9688C192.507 43.1309 192.871 44.2474 193.19 45.3184C193.509 46.3893 193.76 47.4261 193.942 48.4287H194.182C194.501 47.2894 194.956 45.7969 195.549 43.9512C196.141 42.0827 196.779 40.18 197.463 38.2432L205.29 16.5391H211.408L195.104 59.5029C194.239 61.8044 193.225 63.8096 192.062 65.5186C190.923 67.2275 189.533 68.5378 187.893 69.4492C186.252 70.3835 184.27 70.8506 181.945 70.8506C180.874 70.8506 179.929 70.7822 179.108 70.6455C178.288 70.5316 177.582 70.3949 176.989 70.2354V65.6895C177.491 65.8034 178.094 65.9059 178.801 65.9971C179.53 66.0882 180.282 66.1338 181.057 66.1338C182.469 66.1338 183.688 65.8604 184.714 65.3135C185.762 64.7894 186.662 64.0146 187.414 62.9893C188.166 61.9639 188.815 60.7448 189.362 59.332L191.413 54.0684L176.408 16.5391Z"
              fill="black"
            />
            <path
              d="M249.929 4.0293C254.03 4.0293 257.414 4.54199 260.08 5.56738C262.769 6.56999 264.774 8.10807 266.096 10.1816C267.417 12.2552 268.078 14.8984 268.078 18.1113C268.078 20.7546 267.6 22.9648 266.643 24.7422C265.686 26.4967 264.432 27.9209 262.883 29.0146C261.333 30.1084 259.681 30.9515 257.927 31.5439L271.633 54H264.865L252.629 33.1504H242.238V54H236.428V4.0293H249.929ZM249.587 9.05371H242.238V28.2285H250.168C254.247 28.2285 257.243 27.3968 259.157 25.7334C261.094 24.07 262.062 21.6204 262.062 18.3848C262.062 14.9896 261.037 12.5856 258.986 11.1729C256.958 9.76009 253.825 9.05371 249.587 9.05371Z"
              fill="black"
            />
            <path
              d="M298.541 52.0957C298.541 53.1474 297.688 54 296.637 54H288.207C282.5 54 277.873 49.3733 277.873 43.666V27.2129C277.873 21.5056 282.5 16.8789 288.207 16.8789H296.637C297.688 16.8789 298.541 17.7315 298.541 18.7832V18.7832C298.541 19.8349 297.688 20.6875 296.637 20.6875H288.194C284.878 20.6875 282.189 23.376 282.189 26.6924V26.6924C282.189 30.0088 284.878 32.6973 288.194 32.6973H295.723C296.76 32.6973 297.602 33.5385 297.602 34.5762V34.5762C297.602 35.6139 296.76 36.4551 295.723 36.4551H289.058C285.264 36.4551 282.189 39.5301 282.189 43.3232V43.3232C282.189 47.1164 285.264 50.1914 289.058 50.1914H296.637C297.688 50.1914 298.541 51.044 298.541 52.0957V52.0957Z"
              fill="black"
            />
            <path
              d="M321.685 15.9238C326.151 15.9238 329.466 16.9264 331.631 18.9316C333.796 20.9368 334.878 24.1383 334.878 28.5361V54H330.742L329.648 48.4629H329.375C328.327 49.8301 327.233 50.9808 326.094 51.915C324.954 52.8265 323.633 53.5215 322.129 54C320.648 54.4557 318.825 54.6836 316.66 54.6836C314.382 54.6836 312.354 54.2848 310.576 53.4873C308.822 52.6898 307.432 51.4821 306.406 49.8643C305.404 48.2464 304.902 46.1956 304.902 43.7119C304.902 39.9749 306.383 37.1038 309.346 35.0986C312.308 33.0934 316.82 31.9997 322.881 31.8174L329.341 31.5439V29.2539C329.341 26.0182 328.646 23.751 327.256 22.4521C325.866 21.1533 323.906 20.5039 321.377 20.5039C319.417 20.5039 317.549 20.7887 315.771 21.3584C313.994 21.9281 312.308 22.6003 310.713 23.375L308.97 19.0684C310.656 18.2025 312.593 17.4619 314.78 16.8467C316.968 16.2314 319.269 15.9238 321.685 15.9238ZM329.272 35.543L323.564 35.7822C318.893 35.9645 315.601 36.7279 313.687 38.0723C311.772 39.4167 310.815 41.3193 310.815 43.7803C310.815 45.9222 311.465 47.5059 312.764 48.5312C314.062 49.5566 315.783 50.0693 317.925 50.0693C321.252 50.0693 323.975 49.1465 326.094 47.3008C328.213 45.4551 329.272 42.6865 329.272 38.9951V35.543Z"
              fill="black"
            />
            <path d="M352.275 54H346.567V0.816406H352.275V54Z" fill="black" />
            <path
              d="M361.283 54C360.231 54 359.379 53.1474 359.379 52.0957V43.666C359.379 37.9587 364.006 33.332 369.713 33.332H386.166C391.873 33.332 396.5 37.9587 396.5 43.666V52.0957C396.5 53.1474 395.647 54 394.596 54V54C393.544 54 392.691 53.1474 392.691 52.0957V43.6533C392.691 40.3369 390.003 37.6484 386.687 37.6484V37.6484C383.37 37.6484 380.682 40.3369 380.682 43.6533V51.1816C380.682 52.2193 379.84 53.0605 378.803 53.0605V53.0605C377.765 53.0605 376.924 52.2193 376.924 51.1816V44.5166C376.924 40.7234 373.849 37.6484 370.056 37.6484V37.6484C366.262 37.6484 363.188 40.7234 363.188 44.5166V52.0957C363.188 53.1474 362.335 54 361.283 54V54Z"
              fill="black"
            />
          </g>
        </svg>
      </div>
      {displayAccount && (
        <div className="flex items-center">
          {signedIn ? (
            <>
              <User />
            </>
          ) : (
            <>
              <button
                className="bg-secondary hover:bg-third text-white font-semibold py-2 px-4 mr-2 rounded"
                onClick={authClick}
                id="sign-in"
              >
                Sign In
              </button>
              <button
                className="bg-secondary hover:bg-third text-white font-semibold py-2 px-4 rounded"
                onClick={authClick}
                id="sign-up"
              >
                Sign Up
              </button>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

Navbar.defaultProps = {
  displayAccount: true,
};

export default Navbar;
