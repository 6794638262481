import { useEffect } from "react";

function isVowel(character) {
  character = character?.toLowerCase();
  if (character === "a" || "e" || "i" || "o" || "u") {
    return true;
  } else {
    return false;
  }
}

function RejoinPopup(props) {
  const { closePopup, rejoin, game, user } = props;
  const userName = user?.userName;
  const gameId = game?.gameId;
  const gameType = game?.game;

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Escape") {
        // Your code here: This will be executed when the escape key is pressed.
        // For example, you can close a modal, hide a menu, etc.
        closePopup();
      } else if (event.key === "Enter") {
        rejoin();
      }
    }

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-40"></div>
      <div className="bg-white p-8 rounded-lg z-10 relative">
        <button
          onClick={closePopup}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Rejoin Game</h2>
        <p className="mb-2">
          Welcome back, {userName}! You were previously in a
          {isVowel(gameType?.charAt(0)) ? `n` : ``} {gameType} game with ID:{" "}
          {gameId}.
        </p>
        <p className="mb-4">Do you want to rejoin the game?</p>
        <div className="flex justify-end">
          <button
            onClick={closePopup}
            className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={rejoin}
            className="ml-4 px-4 py-2 text-white bg-secondary rounded-lg hover:bg-third focus:outline-none"
          >
            Rejoin
          </button>
        </div>
      </div>
    </div>
  );
}

export default RejoinPopup;
