import { QRCode } from "react-qrcode-logo";
import { FiShare } from "react-icons/fi";
import Navbar from "../Nav/Navbar";
import GameUser from "../GameUser/GameUser";

interface User {
  id: string;
  userName: string;
  gameCreator: boolean;
  host: boolean;
}

interface Game {
  game: string;
  gameId: string;
  numPlayers: number;
  round: number;
  users: Array<User>;
  lastConnected?: number;
}

interface Props {
  game: Game;
  next: any;
  user: any;
}

const GameLobby = ({ game, user, next }: Props) => {
  function share() {
    navigator
      .share({
        title: document.title,
        text: "Check this out",
        url: `https://revelryrealm.com/?gameId=${game?.gameId}`,
      })
      .then(() => console.log("Successful share! 🎉"))
      .catch((err) => console.error(err));
  }
  const gameCreator = game?.users?.find((user) => user.gameCreator === true);


  return (
    <div className="bg-slate-100 h-screen">
      <Navbar displayAccount={false} />
      <div className="w-full max-h-[20vh] px-10">
        <h2 className="text-2xl font-google-sans mb-4">
          {game?.game ? game?.game : `Game`} Lobby
        </h2>
        <h3 className="font-google-sans">
          To join, go to <a href="https://revelryrealm.com">revelryrealm.com</a>{" "}
          and enter <strong>{game?.gameId}</strong> as the game code.
        </h3>
      </div>
      <div className="w-full flex px-10 max-h-[55vh]">
        <div className="w-full lg:w-2/3 max-h-[55vh]">
          <h2 className="font-google-sans font-bold">Users</h2>

          <div className="flex bg-transparent">
            <div className="w-full max-h-[50vh] overflow-auto">
              <ul className="space-y-1">
                {!gameCreator?.host && (
                  <GameUser
                    username={gameCreator?.userName}
                    key={gameCreator?.id}
                    gameCreator={gameCreator?.gameCreator}
                  />
                )}

                {game?.users?.map(
                  (user) =>
                    !user?.host &&
                    !user?.gameCreator && (
                      <GameUser username={user?.userName} key={user?.id} />
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex w-1/3">
          <div className="w-full flex">
            <div className="ml-auto">
              <QRCode
                value={`https://revelryrealm.com/?gameId=${game?.gameId}`}
                logoPaddingStyle="circle"
                qrStyle="dots"
                size={300}
              />
              <button
                type="button"
                onClick={() => share()}
                className="flex my-2 w-[300px] bg-white hover:ring-2 font-medium rounded text-lg px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:focus:ring-blue-800 lg:w-full"
              >
                <div className="mx-auto flex">
                  <p className="mx-1">Share</p>
                  <FiShare className="scale-125 stroke-black mx-1" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {user?.gameCreator && (
        <div className="flex w-full my-10 px-10 items-center justify-between max-h-[10vh]">
          <button
            onClick={next}
            className="m-auto p-2 rounded bg-secondary text-white w-80 flex hover:scale-[1.01] transition-all"
          >
            <p className="m-auto">Next</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default GameLobby;
