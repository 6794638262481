import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Import your components or pages
import Home from "./Pages/Home/Home";
import SignUp from "./Pages/SignUp/SignUp";
import SignIn from "./Pages/SignIn/SignIn";
import ManageAccount from "./Pages/ManageAccount/ManageAccount";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import GameLoader from "./Pages/GameLoader/GameLoader";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";


import store from "./store";
import { Provider } from "react-redux";
import VerificationNeeded from "./Pages/VerificationNeeded/VerificationNeeded";

function App() {
  const clientId = process.env.REACT_APP_CLIENT_ID ?? "";
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={clientId}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/signin" element={<SignIn />}></Route>
            <Route path="/account" element={<ManageAccount />}></Route>
            <Route path="/verify" element={<VerifyEmail />}></Route>
            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/verifyemail" element={<VerificationNeeded/>} ></Route>
            <Route path="/resetpassword/:email/:id" element={<ResetPassword />}></Route>
            <Route path="/play" element={<GameLoader />}></Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
