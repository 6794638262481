import React from "react";

interface GameUserProps {
  username: string;
  gameCreator?: boolean;
  profilePhoto?: string;
  score?: number;
}

const GameUser: React.FC<GameUserProps> = ({
  username,
  gameCreator = false,
  profilePhoto,
  score,
}) => {
  return (
    <div className="bg-white p-2 mb-1 rounded-lg shadow-md">
      <div className="flex justify-between">
        <div className="flex">
          <div className="w-16 h-16 bg-primary rounded-full border-2 border-secondary flex">
            <p className="text-2xl m-auto font-semibold">
              {username?.slice(0, 1).toUpperCase()}
            </p>
          </div>
          <h2 className="text-lg font-semibold ml-2 my-auto">{username}</h2>{" "}
        </div>
        {gameCreator ? (
          <div className="my-auto fill-primary">
            <svg
              width="48px"
              height="48px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 19H20M11.2929 5.70711L8.70711 8.2929C8.31658 8.68342 7.68342 8.68342 7.29289 8.2929L5.70711 6.70711C5.07714 6.07714 4 6.52331 4 7.41422V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V7.41421C20 6.52331 18.9229 6.07714 18.2929 6.70711L16.7071 8.2929C16.3166 8.68342 15.6834 8.68342 15.2929 8.2929L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711Z"
                stroke="#000000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <>
            {score !== undefined && (
              <p className="text-gray-600 my-auto">Score: {score}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GameUser;
