import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useEffect, useState } from "react";
import game from "../../redux/game";

/* Install pure-react-carousel using -> npm i pure-react-carousel */

export default function GameSlider(props) {
  const { setGameSelected, allowSelectGame } = props;

  const [selectedGame, setSelectedGameIndex] = useState(0);

  function setSelectedGame(index){

    if(typeof index == "number" && !games[index]?.comingSoon){
      setSelectedGameIndex(index);
    }
  }

  useEffect(() => {
    setGameSelected(games[selectedGame].title);
  }, [selectedGame]);

  const games = [
    {
      title: "LogoWiz",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Guess brands, unravel designs in the ultimate logo challenge!",
      comingSoon: false,
    },
    {
      title: "DrawQuest",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Craft prompts with words and bring them to life through art!",
      comingSoon: false,
    },
    /*{
      title: "Word Association",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Associate words in a fast-paced game.",
      comingSoon: false,
    },
    {
      title: "Picture Puzzles",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Guess images from blurred or pixelated pictures.",
      comingSoon: false,
    } 
    {
      title: "Character Mashup",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Combine famous characters to create new ones.",
      comingSoon: false,
    },
    {
      title: "Trivia Showdown",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Compete in a multiplayer trivia game.",
      comingSoon: true,
    },
    {
      title: "Action Charades",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Act out actions without speaking.",
      comingSoon: false,
    },
    {
      title: "Fact or Fiction",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Guess if unusual facts are true or false.",
      comingSoon: false,
    },
    {
      title: "Drawing Challenge",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Draw random words within a time limit.",
      comingSoon: false,
    },
    {
      title: "Song Lyrics Mix-Up",
      imgSrc: "https://d2ope3ml5fi0qd.cloudfront.net/uploads/2021/07/C1868.png",
      details: "Sing or recite mixed-up song lyrics.",
      comingSoon: false,
    },*/
    ,
  ];

  return (
    <div className="container mx-auto">
      <div className="flex items-center justify-center w-full h-full px-4">
        {/* Carousel for desktop and large size devices */}
        <CarouselProvider
          className="lg:block hidden"
          naturalSlideWidth={200}
          isIntrinsicHeight={true}
          totalSlides={games.length}
          visibleSlides={3}
          step={1}
          infinite={true}
        >
          <div className="w-full relative flex items-center justify-center">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              id="prev"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                className="scale-150 hover:scale-[2.0] transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonBack>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <Slider>
                <div
                  id="slider"
                  className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
                >
                  {games.map((game, index) => (
                    <>
                      <Slide index={index} key={index}>
                        <div
                          key={`div:${index}`}
                          onClick={() => setSelectedGame(index)}
                          className={`flex flex-shrink-0 relative w-full sm:w-auto scale-[0.95] transition-all ${
                            allowSelectGame && `hover:scale-100`
                          } cursor-pointer rounded-md overflow-hidden ${
                            selectedGame === index &&
                            allowSelectGame &&
                            `border-2 border-secondary`
                          }`}
                        >
                          <img
                            key={`img:${index}`}
                            src={game.imgSrc}
                            alt="black chair and white table"
                            className="object-cover object-center w-full"
                          />
                          <div
                            className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6"
                            key={`div2:${index}`}
                          >
                            <h2
                              className="lg:text-xl leading-4 text-base lg:leading-5 text-white"
                              key={`h2:${index}`}
                            >
                              {game.title}
                            </h2>
                            <div
                              className="flex h-full items-end pb-6"
                              key={`div3:${index}`}
                            >
                              <h3
                                className="text-md lg:text-2xl text-white"
                                key={`h3:${index}`}
                              >
                                {game.details}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </>
                  ))}
                </div>
              </Slider>
            </div>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              id="next"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                className="scale-150 hover:scale-[2.0] transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>

        {/* Carousel for tablet and medium size devices */}
        <CarouselProvider
          className="lg:hidden md:block hidden"
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={games.length}
          visibleSlides={2}
          step={1}
          infinite={true}
        >
          <div className="w-full relative flex items-center justify-center">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              id="prev"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                className="scale-150 hover:scale-[2.0] transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonBack>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <Slider>
                <div
                  id="slider"
                  className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700"
                >
                  {games.map((game, index) => (
                    <>
                      <Slide index={index} key={index}>
                        <div
                          key={`div:${index}`}
                          onClick={() => setSelectedGame(index)}
                          className={`flex flex-shrink-0 relative w-full sm:w-auto scale-[0.95] transition-all ${
                            allowSelectGame && `hover:scale-100`
                          } cursor-pointer rounded-md overflow-hidden ${
                            selectedGame === index &&
                            allowSelectGame &&
                            `border-2 border-secondary`
                          }`}
                        >
                          <img
                            key={`img:${index}`}
                            src={game.imgSrc}
                            alt="black chair and white table"
                            className="object-cover object-center w-full"
                          />
                          <div
                            className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6"
                            key={`div2:${index}`}
                          >
                            <h2
                              className="lg:text-xl leading-4 text-base lg:leading-5 text-white"
                              key={`h2:${index}`}
                            >
                              {game.title}
                            </h2>
                            <div
                              className="flex h-full items-end pb-6"
                              key={`div3:${index}`}
                            >
                              <h3
                                className="text-md lg:text-2xl text-white"
                                key={`h3:${index}`}
                              >
                                {game.details}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </>
                  ))}
                </div>
              </Slider>
            </div>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              id="next"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                className="scale-150 hover:scale-[2.0] transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>

        {/* Carousel for mobile and Small size Devices */}
        <CarouselProvider
          className="block md:hidden "
          naturalSlideWidth={100}
          isIntrinsicHeight={true}
          totalSlides={games.length}
          visibleSlides={1}
          step={1}
          infinite={true}
        >
          <div className="w-full relative flex items-center justify-center">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer"
              id="prev"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                className="scale-150 hover:scale-[2.0] transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonBack>
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
              <Slider>
                <div
                  id="slider"
                  className="h-full w-full flex lg:gap-8 md:gap-6 items-center justify-start transition ease-out duration-700"
                >
                  {games.map((game, index) => (
                    <>
                      <Slide index={index} key={index}>
                        <div
                          key={`div:${index}`}
                          onClick={() => setSelectedGame(index)}
                          className={`flex flex-shrink-0 relative w-full sm:w-auto scale-[0.95] transition-all ${
                            allowSelectGame && `hover:scale-100`
                          } cursor-pointer rounded-md overflow-hidden ${
                            selectedGame === index &&
                            allowSelectGame &&
                            `border-2 border-secondary`
                          }`}
                        >
                          <img
                            key={`img:${index}`}
                            src={game.imgSrc}
                            alt="black chair and white table"
                            className="object-cover object-center w-full"
                          />
                          <div
                            className="bg-gray-800 bg-opacity-30 absolute w-full h-full p-6"
                            key={`div2:${index}`}
                          >
                            <h2
                              className="lg:text-xl leading-4 text-base lg:leading-5 text-white"
                              key={`h2:${index}`}
                            >
                              {game.title}
                            </h2>
                            <div
                              className="flex h-full items-end pb-6"
                              key={`div3:${index}`}
                            >
                              <h3
                                className="text-md lg:text-2xl text-white"
                                key={`h3:${index}`}
                              >
                                {game.details}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </>
                  ))}
                </div>
              </Slider>
            </div>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              id="next"
            >
              <svg
                width={8}
                height={14}
                viewBox="0 0 8 14"
                fill="none"
                className=" scale-150 hover:scale-[2.0] transition-all"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L1 13"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </div>
  );
}
