import { useState, useEffect } from "react";
const Intro = (props) => {
  const [showOpaque, setShowOpaque] = useState(false);
  useEffect(() => {
    setShowOpaque(true);
  }, []);

  const text =
    "Welcome to DrawQuest. In this game, you and your rivals will compete to see who knows these popular logos the best.";
  const typingSpeed = 50;
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const textArray = text.split("");

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentIndex < textArray.length) {
        setTypedText((prevText) => prevText + textArray[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [text, textArray, typingSpeed]);

  return (
    <>
      <div className="my-auto w-screen text-center">
        <h2
          className={`${showOpaque ? "opacity-100" : "opacity-0"}
          transition-opacity duration-[1000ms] ease-in-out mx-auto fill-primary text-primary text-6xl sm:text-8xl md:text-[8rem] lg:text-[12rem] xl:text-[15rem]`}
        >
          Draw Quest
        </h2>
        <p className="mx-auto font-google-sans text-secondary">{typedText}</p>
      </div>
    </>
  );
};

export default Intro;
