import { useState } from "react";
import Error from "../../Components/Error/Error";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const requestReset = async () => {
    try {
      let url = "https://revelryrealm.com/api/forgotpassword";
      const data = {
        email: email,
      };
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status !== 200) {
        throw new Error("Hello");
      }
      setVerifyEmail(true);

      response = await response.json();
    } catch (e) {
      setError(true);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // handleSignin();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        {verifyEmail ? (
          <>
            {" "}
            <div className="flex flex-col items-center justify-center bg-white">
              <div className="flex items-center justify-center bg-green-500 rounded-full w-24 h-24 mb-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  className="checkmark-icon w-12 h-12"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h1 className="text-2xl font-bold mb-4">
                An email has been sent to reset your password
              </h1>
              <p className="text-lg text-gray-700">
                Please follow the instructions in the email.
              </p>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-semibold mb-4">Reset Password</h2>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleKeyPress}
                className="w-full pr-8 pl-4 py-2 border rounded"
              />
            </div>

            <button
              onClick={requestReset}
              className="w-full bg-secondary hover:bg-third text-white font-semibold py-2 rounded"
            >
              Reset
            </button>

            {error && (
              <Error
                errorTitle="Sign in error"
                errorMessage="Error resetting password"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SignIn;
