import { useState, useEffect } from "react";

const RoundOne = ({ game, submit }: any) => {
  const [loaded, setLoaded] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [answer, setAnswer] = useState("");
  const [secondsToNext, setSecondsToNext] = useState(10);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsLeft = game?.nextRoundTime - Math.round(Date.now() / 1000);
      setSecondsToNext(secondsLeft);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const questions = game?.questions;
    let url = "";
    if (questions !== undefined) {
      if (imageIndex >= questions[game?.round].length) {
        setComplete(true);
      }
      if (questions[game?.round] !== null) {
        url = questions[game?.round][imageIndex]?.url;
      }
    }
    if (typeof url != "string") {
      url = "";
    } else {
      setLoaded(true);
    }
    setImageUrl(url);
  }, [game?.questions, imageIndex]);

  function submitAnswer() {
    setImageIndex(imageIndex + 1);
    setAnswer("");
    submit(answer, imageIndex);
  }

  function handleSkip() {
    setImageIndex(imageIndex + 1);
    setAnswer("");
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitAnswer();
    }
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-md p-6 my-6 mx-auto w-[75vw] md:w-[40vw]">
        <p className="text-xl font-semibold text-right">
          {secondsToNext > 60 ? (
            <strong>{Math.round(secondsToNext / 60)}</strong>
          ) : (
            <strong>{secondsToNext}</strong>
          )}
          {secondsToNext > 60
            ? ` minutes until the next round`
            : ` seconds until the next round`}
        </p>
        {complete ? (
          <div className="h-full flex">
            <div className="m-auto">
              <h2 className="text-xl font-semibold mb-4">
                Now we wait for the others to finish...
              </h2>
            </div>
          </div>
        ) : (
          <div className="h-full flex">
            <div className="m-auto">
              <h2 className="text-xl font-semibold mb-4">{`LogoWiz Logo #${
                imageIndex + 1
              }`}</h2>
              <div className="mx-auto relative">
                <img
                  src={imageUrl}
                  alt="Spinning Image"
                  className={`transition-opacity xs:w-[70vw] sm:w-[50vw] md:w-[30vw] max-h-[60vh] opacity-0 ${
                    loaded && `opacity-100`
                  } duration-1000`}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="cardInput"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Logo
                </label>
                <input
                  id="cardInput"
                  type="text"
                  onInput={(e: any) => {
                    setAnswer(e.target.value);
                  }}
                  onKeyDown={(e) => handleKeyPress(e)}
                  value={answer}
                  className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300"
                  placeholder="Enter your best guess!"
                />
              </div>
              <div className="flex justify-between">
                <button
                  className="text-blue-500 font-medium"
                  onClick={handleSkip}
                >
                  Skip
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                  onClick={submitAnswer}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoundOne;
