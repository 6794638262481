import { useState, useEffect } from "react";
import GameUser from "../GameUser/GameUser";

const LeaderBoard = ({ users, text }) => {
  const userCopy = JSON.parse(JSON.stringify(users));
  userCopy.sort((a, b) => b.score - a.score);

  const topThree = userCopy?.slice(0, 3);

  const typingSpeed = 50;
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const textArray = text.split("");

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentIndex < textArray.length) {
        setTypedText((prevText) => prevText + textArray[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [text, textArray, typingSpeed]);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 my-6 mx-auto xs:w[100vw] sm:w-[75vw] md:w-[40vw]">
      <h1 className="text-2xl font-semibold">Leaderboard</h1>
      <h2 className="text-xl">{typedText}</h2>
      <div className="flex justify-center items-center w-full ">
        <div className="m-auto">
          <div className="relative w-96 h-96">
            {topThree[2] !== undefined && (
              <div className="absolute top-1/2 right-1/2 translate-x-[125%] text-center">
                <div className="w-32 h-32 bg-primary rounded-full flex border-2 border-secondary">
                  <p className="text-6xl m-auto font-semibold">
                    {topThree[2]?.userName.slice(0, 1).toUpperCase()}
                  </p>
                </div>
                <span className="text-secondary font-google-sans">
                  {topThree[2]?.userName}
                </span>
                <br></br>
                <span className="text-secondary font-google-sans">3</span>
              </div>
            )}

            <div className="absolute top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 text-center z-10">
              <svg
                width="80px"
                height="80px"
                viewBox="0 0 24 24"
                className="mx-auto"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 19H20M11.2929 5.70711L8.70711 8.2929C8.31658 8.68342 7.68342 8.68342 7.29289 8.2929L5.70711 6.70711C5.07714 6.07714 4 6.52331 4 7.41422V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V7.41421C20 6.52331 18.9229 6.07714 18.2929 6.70711L16.7071 8.2929C16.3166 8.68342 15.6834 8.68342 15.2929 8.2929L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711Z"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="w-32 h-32 bg-primary rounded-full border-2 border-secondary flex">
                <p className="text-6xl m-auto font-semibold">
                  {topThree[0]?.userName.slice(0, 1).toUpperCase()}
                </p>
              </div>
              <span className="text-secondary font-google-sans">
                {topThree[0]?.userName}
              </span>
              <br></br>
              <span className="text-secondary font-google-sans">1</span>
            </div>
            {topThree[1] !== undefined && (
              <div className="absolute top-1/2 right-1/2 -translate-x-1/4 text-center">
                <div className="w-32 h-32 bg-primary rounded-full border-2 border-secondary flex">
                  <p className="text-6xl m-auto font-semibold">
                    {topThree[1]?.userName.slice(0, 1).toUpperCase()}
                  </p>
                </div>
                <span className="text-secondary font-google-sans">
                  {topThree[1]?.userName}
                </span>
                <br></br>
                <span className="text-secondary font-google-sans">2</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full border-t my-2 py-2 overflow-auto max-h-[45vh]">
        {userCopy.map((user) => (
          <GameUser
            username={user?.userName}
            score={user?.score}
            profilePhoto=""
          />
        ))}
      </div>
    </div>
  );
};

export default LeaderBoard;
