import React from "react";
import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import Error from "../../Components/Error/Error";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/auth";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";

const provider = new GoogleAuthProvider();

const SignUp = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const googleSignUp = async (token) => {
    try {
      let url = "https://revelryrealm.com/api/googlesignin";
      const data = {
        accessToken: token,
      };

      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status !== 200) {
        throw new Error("An Error Occured");
      }
      response = await response.json();
      dispatch(setAuth(response));
      navigate("/");
    } catch (e) {
      setError(true);
    }
  };

  const handleSignup = async () => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        sendEmailVerification(auth.currentUser).then(() => {
          window.localStorage.setItem("emailForSignIn", email);
          navigate("/verifyemail");
        });
      })
      .catch((error) => {
        setError(true);
        // ..
      });
  };

  function googleLogin() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate("/");
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        setError(true);
        // ...
      });
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSignup();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <>
          <h2 className="text-2xl font-semibold mb-4">Sign up</h2>

          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleKeyPress}
              className="w-full pr-8 pl-4 py-2 border rounded"
            />
          </div>
          <div className="relative mb-6">
            <input
              type={passwordType}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              className="block w-full pr-8 pl-4 py-2 border rounded"
            />

            <button
              className="btn btn-outline-primary absolute right-2.5 bottom-3.5"
              onClick={togglePassword}
            >
              {passwordType === "password" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              )}
            </button>
          </div>
          <button
            onClick={handleSignup}
            className="w-full bg-secondary hover:bg-third text-white font-semibold py-2 rounded"
          >
            Sign up
          </button>
          <div className="my-6">
            <p className="text-gray-600 text-center">
              Already have an account?{" "}
              <a href="/signin" className="text-secondary">
                Sign in
              </a>
            </p>
          </div>
          <div className="flex items-center justify-center mt-4">
            <span className="text-gray-600">Or sign up with&nbsp; &nbsp; </span>
            <button
              onClick={googleLogin}
              className="border h-10 px-3 flex max-h-12 rounded border-slate-200 items-center"
            >
              <div className="w-5 h-5 mr-2">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                >
                  <g>
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </g>
                </svg>
              </div>
              <span className="sans-serif text-sm text-gray-600">
                Sign up with Google
              </span>
            </button>
            {error && (
              <Error
                setExternalDisplay={error}
                errorMessage="Error signing up"
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default SignUp;
