import { createSlice } from "@reduxjs/toolkit";

export const user = createSlice({
  name: "user",
  initialState: {
    value: JSON.parse(
      window.localStorage.getItem("revelryrealm-game-user") || "null"
    ),
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
      window.localStorage.setItem(
        "revelryrealm-game-user",
        JSON.stringify(action.payload)
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = user.actions;

export default user.reducer;
