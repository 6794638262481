import { useState, useEffect } from "react";
import Navbar from "../../Components/Nav/Navbar";
import GameSlider from "../../Components/GameSlider/GameSlider";
import RejoinPopup from "../../Components/RejoinPopup/RejoinPopup";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setNewGameJoin } from "../../redux/newGameJoin";
import Error from "../../Components/Error/Error";
import { setUser } from "../../redux/user";
import { setGame } from "../../redux/game";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Home = () => {
  const auth = getAuth();
  const game = useSelector((state) => state.game.value);
  const user = useSelector((state) => state.user.value);

  const [signedIn, setSignedIn] = useState(false);

  onAuthStateChanged(auth, (user) => {

    if (user && user.emailVerified && !signedIn) {
      setSignedIn(true);
    } else if (!user?.emailVerified) {
      setSignedIn(false);
    }
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("gameId");
    if (game?.lastConnected + 60 * 60 * 1000 < Date.now()) {
      dispatch(setUser(null));
      dispatch(setGame(null));
    } else if (
      game?.lastConnected + 60 * 60 * 1000 > Date.now() &&
      user !== null &&
      game?.round !== 0 &&
      user
    ) {
      setPromptRejoin(true);
    }
    if (myParam?.length === 4) {
      setGameCode(myParam);
    }
  }, []);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [gameCode, setGameCode] = useState("");
  const [userName, setUsername] = useState("");
  const [displayCreate, setDisplayCreate] = useState(false);
  const [joinAsHost, setJoinAsHost] = useState(false);
  const [gameSelected, setGameSelected] = useState("");
  const [promptRejoin, setPromptRejoin] = useState(false);
  const [gameError, setGameError] = useState({
    display: false,
    errorTitle: "",
    errorMessage: "",
  });

  const handleGameCodeChange = (e) => {
    setGameCode(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const handleJoinGame = () => {
    if (gameCode.length === 4 && userName.length > 0) {
      dispatch(setNewGameJoin({ gameId: gameCode, userName: userName }));
      navigate(`/play`);
    } else {
      setGameError({
        display: true,
        errorTitle: "Error Joining Game",
        errorMessage:
          gameCode.length === 4
            ? `Enter Valid Username`
            : `Enter Valid Game Code`,
      });
      const timer = setTimeout(() => {
        setGameError({
          display: false,
          errorTitle: "",
          errorMessage: "",
        });
        clearTimeout(timer);
      }, 3000);
    }

    // Handle joining the game with the entered code
  };

  const handleCreateGame = async () => {
    if (!joinAsHost && userName.length === 0) {
      setGameError({
        display: true,
        errorTitle: "Error Joining Game",
        errorMessage: `Enter Valid Username`,
      });
      const timer = setTimeout(() => {
        setGameError({
          display: false,
          errorTitle: "",
          errorMessage: "",
        });
        clearTimeout(timer);
      }, 3000);
    } else {
      let body = {
        game: gameSelected,
        accessToken: auth?.token,
        userName: userName,
        host: joinAsHost,
      };
      try {
        let response = await fetch("https://revelryrealm.com/api/creategame", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        if (response.status !== 200) {
          response = await response.json();
          throw new Error(response.error);
        }
        response = await response.json();
        dispatch(setNewGameJoin(response));
        dispatch(setUser(null));
        dispatch(setGame(null));
        navigate(`/play`);
      } catch (error) {
        console.error(error);
      }
    }
    // navigate();
  };

  return (
    <>
      <div className="min-h-screen bg-slate-100">
        <Navbar signedIn={signedIn}></Navbar>
        {promptRejoin && (
          <RejoinPopup
            game={game}
            user={user}
            closePopup={() => setPromptRejoin(false)}
            rejoin={() => navigate("/play")}
          />
        )}
        <div className="h-[85vh] block md:flex items-center justify-center overflow-hidden">
          {gameError.display && (
            <Error
              setExternalDisplay={(newState) => {
                setGameError({
                  errorMessage: gameError.errorTitle,
                  errorMessage: gameError.errorMessage,
                  display: newState,
                });
              }}
              errorTitle={gameError.errorTitle}
              errorMessage={gameError.errorMessage}
            />
          )}

          <div className="w-full md:w-1/3 px-3 md:p-10">
            <h2 className="text-4xl mb-6 text-font-color">
              {displayCreate ? "Start a game" : "Join the revelry"}
            </h2>
            <div className="flex flex-col">
              {!displayCreate && (
                <input
                  type="text"
                  placeholder="Enter game code"
                  className="border border-gray-300 p-4 rounded mb-4 w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                  value={gameCode}
                  onChange={handleGameCodeChange}
                />
              )}
              {!displayCreate && (
                <input
                  type="text"
                  placeholder="Enter username"
                  className="border border-gray-300 p-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                  value={joinAsHost && displayCreate ? `` : userName}
                  disabled={joinAsHost && displayCreate}
                  onChange={handleUsernameChange}
                />
              )}

              {displayCreate && (
                <>
                  <div className="flex w-full">
                    <input
                      type="text"
                      placeholder="Enter username"
                      className="border border-gray-300 p-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                      value={joinAsHost && displayCreate ? `` : userName}
                      disabled={joinAsHost && displayCreate}
                      onChange={handleUsernameChange}
                    />
                  </div>

                  <div className="flex w-full">
                    <button
                      className={`hidden lg:block bg-gray-400 text-white w-full px-4 py-2 mt-4 rounded hover:ring-2 hover:ring-fourth focus:outline-none focus:ring-2 focus:ring-secondary ${
                        joinAsHost && `ring-2 ring-fourth`
                      }`}
                      onClick={() => setJoinAsHost(!joinAsHost)}
                    >
                      Join as host
                    </button>
                  </div>
                </>
              )}
              {
                <button
                  className="bg-secondary text-white px-4 py-2 mt-4 rounded hover:bg-third focus:outline-none focus:ring-2 focus:ring-secondary"
                  onClick={!displayCreate ? handleJoinGame : handleCreateGame}
                >
                  {displayCreate ? "Create Game" : "Join Game"}
                </button>
              }
              {signedIn && (
                <>
                  <p className="m-auto text-2xl text-font-color">or</p>
                  <button
                    className="bg-secondary text-white px-4 py-2 my-2 rounded hover:bg-third focus:outline-none focus:ring-2 focus:ring-secondary"
                    onClick={() => setDisplayCreate(!displayCreate)}
                  >
                    {displayCreate ? "Join Game" : "Create Game"}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="w-full md:block md:w-2/3">
            {/* Graphic */}
            <div className="flex justify-center items-center h-full">
              <GameSlider
                setGameSelected={setGameSelected}
                allowSelectGame={displayCreate && auth !== null}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
