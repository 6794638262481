import { createSlice } from "@reduxjs/toolkit";

export const game = createSlice({
  name: "game",
  initialState: {
    value: JSON.parse(
      window.localStorage.getItem("revelryrealm-game") || "null"
    ),
  },
  reducers: {
    setGame: (state, action) => {
      if (
        action.payload !== null &&
        action.payload.hasOwnProperty("lastConnected")
      ) {
        state.value = action.payload;
      } else if (
        action.payload !== null &&
        state.value.hasOwnProperty("lastConnected")
      ) {
        state.value = {
          ...action.payload,
          lastConnected: state.value.lastConnected,
        };
      } else {
        state.value = action.payload;
      }

      window.localStorage.setItem(
        "revelryrealm-game",
        JSON.stringify(state.value)
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGame } = game.actions;

export default game.reducer;
