import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {
    value: JSON.parse(
      window.localStorage.getItem("revelryrealm-user") || "null"
    ),
  },
  reducers: {
    setAuth: (state, action) => {
      state.value = action.payload;
      window.localStorage.setItem(
        "revelryrealm-user",
        JSON.stringify(action.payload)
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth } = auth.actions;

export default auth.reducer;
