import React from "react";

interface PlanCardProps {
  planName: string;
  bulletPoints: string[];
  isSelected: boolean;
  onClick: any;
}

function PlanCard({
  planName,
  bulletPoints,
  isSelected,
  onClick,
}: PlanCardProps) {
  return (
    <button
      onClick={onClick}
      className={`w-full md:w-56 p-3 border rounded-lg transition-transform duration-300 text-left bg-gray-100 ${
        isSelected ? "transform scale-105 border-secondary" : ""
      }`}
    >
      <h2 className="text-sm md:text-md font-bold">{planName}</h2>
      <ul className="mt-4 text-xs md:text-sm list-disc list-inside">
        {bulletPoints.map((bulletPoint, index) => (
          <li key={index}>{bulletPoint}</li>
        ))}
      </ul>
    </button>
  );
}

export default PlanCard;
