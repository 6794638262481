import { useState } from "react";
import Error from "../../Components/Error/Error";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const { email, id } = useParams();

  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const resetPassword = async () => {
    try {
      let url = "https://revelryrealm.com/api/resetpassword";
      const data = {
        email: email,
        resetPasswordId: id,
        password: password,
      };
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status !== 200) {
        throw new Error("An Error Occured");
      }
      setSuccess(true);

      response = await response.json();
    } catch (e) {
      setError(true);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      resetPassword();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        {success ? (
          <>
            <div className="flex flex-col items-center justify-center bg-white">
              <div className="flex items-center justify-center bg-green-500 rounded-full w-24 h-24 mb-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  className="checkmark-icon w-12 h-12"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h1 className="text-2xl font-bold mb-4">
                Your password has been reset
              </h1>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-semibold mb-4">Reset Password</h2>

            <div className="mb-4 text-gray-700">
              <input
                type="email"
                placeholder="Email"
                value={email}
                disabled
                className="w-full pr-8 pl-4 py-2 border rounded"
              />
            </div>
            <div className="relative mb-6">
              <input
                type={passwordType}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                className="block w-full pr-8 pl-4 py-2 border rounded"
              />

              <button
                className="btn btn-outline-primary absolute right-2.5 bottom-3.5"
                onClick={togglePassword}
              >
                {passwordType === "password" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye-slash-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                  </svg>
                )}
              </button>
            </div>
            <button
              onClick={resetPassword}
              className="w-full bg-secondary hover:bg-third text-white font-semibold py-2 rounded"
            >
              Reset Password
            </button>
          </>
        )}
        {error && (
          <Error
            setExternalDisplay={setError}
            errorMessage="There an error with your request"
            errorTitle="Password Reset Errro"
          />
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
