import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

function User() {
  const [isOpen, setIsOpen] = useState(false);
  const auth = getAuth();

  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function logout() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  return (
    <>
      <div className="relative">
        {!isOpen && (
          <div className="origin-top-right absolute right-0 mt-0 top-[-1.5rem]">
            <button
              className={`bg-white text-gray-700 rounded-full p-3 focus:outline-none`}
              onClick={toggleDropdown}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#115173"
                className="bi bi-person-circle scale-150"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fillRule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
            </button>
          </div>
        )}
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-0 top-[-1.5rem] w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="pb-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="flex">
                {auth && (
                  <button
                    className="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-11/12"
                    role="menuitem"
                  >
                    Billing
                  </button>
                )}
                {!auth && (
                  <Link
                    to="/account"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-11/12"
                    role="menuitem"
                  >
                    Manage Account
                  </Link>
                )}
                <button
                  className={`bg-white text-gray-700 rounded-full p-3 focus:outline-none`}
                  onClick={toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#115173"
                    className="bi bi-person-circle scale-150"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </button>
              </div>
              {auth && (
                <Link
                  to="/account"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Manage Account
                </Link>
              )}

              <a
                href="#"
                onClick={logout}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                Logout
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default User;
